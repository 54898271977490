import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FormLabel, Stack } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useThemePalette } from "common/hooks/theme_palette";

export default function CustomSelect({
  name,
  width,
  value,
  label,
  handleChange,
  options,
  error,
  sx,
}) {
  const { darkGray } = useThemePalette();
  return (
    <Stack
      sx={{
        width,
        height: "fit-content",
        gap: 1,
      }}
    >
      <FormLabel sx={{ color: darkGray, fontFamily: "Alef", fontSize: "18px" }}>{label}</FormLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        name={name}
        onChange={handleChange}
        sx={{
          borderRadius: "36px",
          ...sx,
          ".MuiSelect-icon": {
            fontSize: "35px",
          },
        }}
        IconComponent={KeyboardArrowDownIcon}
      >
        {options?.map((opt, i) => (
          <MenuItem value={opt} key={i}>
            {opt}
          </MenuItem>
        ))}
      </Select>
      {error && error}
    </Stack>
  );
}
