import { Box, Stack } from "@mui/material";
import Footer from "components/Molecules/Footer";
import Header from "components/Molecules/Header";
import React from "react";

import { Outlet } from "react-router-dom";

const Layout = ({ layoutData, size }) => {
  return (
    <>
      <Header />
      <Outlet />
      <Footer size={size} />
    </>
  );
};

export default Layout;
