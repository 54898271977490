import { images } from "assets/AssetHelper";
import Header from "components/Organisms/page-header";
import Incorporating from "components/Organisms/services/SMS/promotional/Incorporating";
import Introduction from "components/Organisms/services/SMS/promotional/Introduction";
import Stepper from "components/Organisms/services/SMS/promotional/Stepper";
import Strategies from "components/Organisms/services/SMS/promotional/Strategies";
import NewsLetter from "components/Molecules/Footer/NewsLetter";

export default function PromotionalTemplate({ steps, strategies, size }) {
  return (
    <>
      <Header
        title="Promotional"
        body="Get to grips with promotional SMS, that can boost your marketing strategies and customer engagement."
        isActions
      />
      <Introduction
        title="What are promotional SMS?"
        body="Promotional SMS are custom-built for marketing and advertising activities. Ensuring that your campaigns, discounts, and deals reach audiences through the most global channel, promotional text messages give your communication maximum visibility. This tool is easy to use, swift, and secure, making it an ideal choice for deploying marketing initiatives and engaging with customers. Boost engagement and conversion metrics, and enhance brand satisfaction with promotional text messages as part of your campaign tactics."
        image={images.Envelope}
        collapse
      />
      <Incorporating
        title="Incorporating promotional SMS into the customer experience"
        body="Utilize promotional text messages to communicate with customers at every phase of their journey. Prevent your hard-earned marketing efforts from getting buried in an overflowing email inbox. By reaching out with discounts, special deals, and loyalty benefits on a platform they'll definitely notice, you'll be sure to capture their attention."
        collapse={size <= 900}
      />
      <Stepper steps={steps} />
      <Strategies
        title="Effective strategies for promotional text messages"
        body="Developing a marketing campaign via text messaging is an uncomplicated and quick method to distribute your meticulously crafted promotional texts and ensure they're noticed. But how can you ascertain that your customers are indeed reading your SMS communications?"
        collapse={size <= 900}
        initialLength={110}
        strategies={strategies}
      />
      <NewsLetter />      

    </>
  );
}
