import { Routes, Route } from "react-router-dom";
import HomePage from "pages/home/Home.Page.jsx";
import { useState, useEffect } from "react";
import Layout from "components/Organisms/layout";
import WhatsAppPage from "pages/services/whatsapp/WhatsApp.Page";
import AuthenticationPage from "pages/services/SMS/authentication/Authentication.Page";
import PromotionalPage from "pages/services/SMS/promotional/Promotional.page";
import TransactionalPage from "pages/services/SMS/transactional/Transactional.Page";
import RemindersPage from "pages/services/SMS/reminders/Reminders.Page";
import NotificationsPage from "pages/services/SMS/notifications/Notifications.Page";
import AboutUsPage from "pages/about-us/AboutUs.Page";
import ContactUsPage from "pages/contact-us/ContactUs.Page";
import SupportPage from "pages/support/Support.Page";
import CareersPage from "pages/careers/Careers.Page";
import SMSPricingPage from "pages/pricing/SMSPricing.Page";
import TermsAndConditionsPage from "pages/terms-conditions/TermsAndConditions.Page";
import PrivacyPolicyPage from "pages/privacy-policy/PrivacyPolicy.Page";
import VoicePage from "pages/services/voice/Voice.page";
import WholeSalesPage from "pages/services/wholesales/WholeSales.page";
import VirtualNumbersPage from "pages/services/VirtualNumbers/VirtualNumbers.Page";
import VoicePricingPage from "pages/pricing/VoicePricing.Page";
import NumbersPricingPage from "pages/pricing/NumbersPricing.Page";
import RCSPage from "pages/services/RCS/RCS.Page";

const AppRoute = () => {
  // this state is for update breadcrumb data for each page
  const [layoutData, setLayoutData] = useState(null);

  const [size, setSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setSize(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <Routes>
      {/* admin layout */}
      <Route path="/" element={<Layout layoutData={layoutData} size={size} />}>
        <Route index element={<HomePage setLayoutData={setLayoutData} />} />
        <Route path="/sms/promotional" element={<PromotionalPage size={size} />} />
        <Route path="/RCS" element={<RCSPage size={size} />} />
        <Route path="/voice-enterprise" element={<VoicePage size={size} />} />
        <Route path="/wholesales" element={<WholeSalesPage size={size}/>} />
        <Route path="/virtual-numbers" element={<VirtualNumbersPage size={size}/>} />

        <Route path="/sms/authentication" element={<AuthenticationPage size={size} />} />
        <Route path="/sms/transactional" element={<TransactionalPage size={size} />} />
        <Route path="/sms/reminders" element={<RemindersPage size={size} />} />
        <Route path="/sms/notifications" element={<NotificationsPage size={size} />} />
        <Route path="/whatsapp" element={<WhatsAppPage />} />
        <Route path="/about-us" element={<AboutUsPage size={size} />} />
        <Route path="/pricing/sms" element={<SMSPricingPage size={size} />} />
        <Route path="/pricing/voice" element={<VoicePricingPage size={size} />} />
        <Route path="/pricing/numbers" element={<NumbersPricingPage size={size} />} />

        <Route path="/careers" element={<CareersPage />} />
        <Route path="/support" element={<SupportPage />} />
        <Route path="/contact-us" element={<ContactUsPage />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />

        <Route element={<p>Not found</p>} />
      </Route>
    </Routes>
  );
};

export default AppRoute;
