import Header from "components/Organisms/page-header";
import { images } from "assets/AssetHelper";
import NewsLetter from "components/Molecules/Footer/NewsLetter";
import UseCases from "components/Organisms/services/RCS/useCases";
import CommServices from "components/Organisms/services/RCS/CommServices";
import Verification from "components/Organisms/services/RCS/Verification";
import Features from "components/Organisms/services/RCS/Features";
import RCS from "components/Organisms/services/RCS/RCS";
import RCSCards from "components/Organisms/services/RCS/RCSCards";
export default function RCSTemplate({ RCSUseCases,features,featuresData, size }) {
  return (
    <>
      <Header
        title="Rich Communication Services"
        body="Experience RCS: Revolutionizing Text Messaging Transform your text messages with rich media for unparalleled in-app customer engagement."
        isActions
      />
      <UseCases data={RCSUseCases} size={size} />
      <CommServices
        title="Elevate Your Communication Services"
        body="Experience the true essence of Rich Communication Services. With advanced two-way customer engagement options that rival social messaging apps, your brand will shine and stand out from the competition."
        image={images.Mobile}
        isAnimationLeft={true}
      />
      <Verification
      title="Enhance Customer Trust with RCS Verification"
      body="Customers are more likely to engage meaningfully when they trust your brand. RCS verified sender profiles use visual cues like branding and checkmarks to build and enhance that trust."
      cardData={[
        { value: "1.4B", label: "Revenue in 2023",  },
        { value: "140%", label: "Higher CTR", },
        { value: "800M", label: "Active users in 2023",  },
        { value: "75%", label: "Increase in conversations",  }
      ]} />
      <Features 
       title="Engage Customers with Rich Communication Features"
       body="Utilize the extensive features of RCS to captivate your customers,build trust, and foster long-lasting relationships through dynamic and interactive communication."
       collapse={size <= 900}
       initialLength={110}
       features={features}/>
       <RCS 
       title="RCS: Combining the Best of SMS and Messaging Apps"
       body="Explore how RCS Business Messaging can elevate your brand by delivering interactive content, boosting customer engagement, and enhancing brand visibility."
       collapse={size <= 900}
       />
       <RCSCards featuresData={featuresData} />
      <NewsLetter />
    </>
  );
}
