import { images } from "assets/AssetHelper";
import CustomAccordion from "components/Atoms/Accordion/CustomAccordion";
import Header from "components/Organisms/page-header";
import Introduction from "components/Organisms/services/SMS/promotional/Introduction";
import CommonPasswordMistakes from "components/Organisms/services/SMS/authentication/CommonPasswordMistakes";
import AuthenticationVarieties from "components/Organisms/services/SMS/authentication/AuthenticationVarieties";
import Security from "components/Organisms/services/SMS/authentication/Security";
import NewsLetter from "components/Molecules/Footer/NewsLetter";
import SimpleAccordion from "components/Atoms/Accordion/Accordion";

export default function AuthenticationTemplate({ passwordMistakes, authVarieties, size }) {
  return (
    <>
      <Header
        title="Two Factor Authentication"
        titleStyle={{ fontSize: { xs: "42px", sm: "70px" } }}
        body="Safeguard your customers against fraud using SMS two-factor authentication for enhanced verification services."
        isActions
      />
      <Introduction
        title="Is Dependence on Passwords Still Justifiable ?"
        body=" Provide secure interactions, offer continuous customer support, and design distinctive journeys across various industries on the world's most widely used chat app."
        image={size >= 900 ? images.Hacker : null}
        otherChildren={
          <SimpleAccordion
            title="Common Mistakes Made When Creating Passwords Include :"
            body={<CommonPasswordMistakes passwordMistakes={passwordMistakes} size={size} />}
          />
        }
      />
      <AuthenticationVarieties
        title="Authentication Varieties"
        body="Companies can shield their customers from attacks exploiting weak passwords by introducing a supplementary protection layer through:"
        image={images.AuthVerify}
        authVarieties={authVarieties}
      />
      <Security title="According to Dataprot" image={images.SecurityLock} size={size} />
      <NewsLetter />      

    </>
  );
}
