import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Stack } from "@mui/material";
import GradientBorderedCard from "components/Molecules/Cards/GradientBorderedCard";

export default function CustomAccordion({ title, body }) {
  return (
    <Accordion
      sx={{
        width: { xs: "100%", sm: "100%" },
        boxShadow: "none",
        border: "1px solid transparent",
      }}
    >
      <GradientBorderedCard
        cardsx={{
          alignItems: "start",
          width: { xs: "100%", md: "100%" },
          border: "solid 2px transparent", 
          borderRadius: "10px", 
          backgroundImage:
            "linear-gradient(white, white), radial-gradient(circle at top left, #94f3da, #eaaec8)",
          backgroundOrigin: "border-box",
          backgroundClip: "padding-box, border-box",
          p: 0, 
          m: 0, 
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              fontSize="medium"
              sx={{
                color: "#272727",
              }}
            />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            flexDirection: "row",
            alignItems: "center",
          
            width: '100%', 
            '& .MuiAccordionSummary-content': {
              m: 0, 
            },
          }}
        >
          <Stack >
            <Typography
              fontFamily="Alef"
              fontWeight={700}
              alignItems="flex-start"
              fontSize={{ xs: "18px", sm: "20px", lg: "18px" }}
              sx={{ width: '100%', p: 0, m: 0 }} // Ensure Typography has no padding or margin
            >
              {title}
            </Typography>
          </Stack>
        </AccordionSummary>
      </GradientBorderedCard>
      <AccordionDetails sx={{ p: 0, ml: 2 }}>
        {body && body}
      </AccordionDetails>
    </Accordion>
  );
}
