import { Box, Stack, Typography } from "@mui/material";
import AppContainer from "components/Atoms/Container/AppContainer";
import Body from "components/Molecules/Section/Body";
import { motion } from "framer-motion";

export default function Reasons({ data, size }) {
  return (
    <AppContainer sx={{ py: 5, gap: 5, px: { xs: 0, lg: 15 }}} >
      {data.map((elem, i) => (
        <Stack
          key={i}
          sx={{
            flexDirection: { xs: "", md: i % 2 === 0 ? "row" : "row-reverse" },
            gap: 10,
          }}
          alignItems="center"
        >
          <Stack
            sx={{
              width: { xs: "100%", md: "75%" },
              gap: 5,
              textAlign: { xs: "center", md: "start" },
            }}
          >
            <Typography
              sx={{ fontFamily: "Alef", fontWeight: 700, fontSize: { xs: "20px", sm: "18px" } }}
            >
              {elem.title}
            </Typography>
            <Body
              text={elem.body}
              sx={{
                fontFamily: "Alef",
                fontSize: "16px",
                textAlign: { xs: "center", md: "start" },
              }}
              collapse={size <= 900}
              initialLength={100}
            />
          </Stack>
          <Stack sx={{ width: { xs: "100%", md: "25%" }, overflow: "hidden" }}>
  <motion.div
    style={{ display: 'flex' }}//justifyContent: i % 2 === 0 ?'flex-start':'flex-end'
    whileInView={{ opacity: [0, 1], x: i % 2 === 0 ? [100, 0] : [-100, 0] }}
    transition={{
      ease: "easeOut",
      duration: 2,
      delay: 0.2,
      type: "spring",
      stiffness: 100,
    }}
  >
    <Box
      component="img"
      src={elem.image}
      width={{ xs: "70%", md: "100%" }}
    />
  </motion.div>
</Stack>
        </Stack>
      ))}
    </AppContainer>
  );
}
