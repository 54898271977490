import { Stack } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import GradientBtn from "components/Atoms/Buttons/GradientBtn";
import AppContainer from "components/Atoms/Container/AppContainer";
import FormField from "components/Atoms/Input/FormInput/FormField";
import Title from "components/Molecules/Section/Title";
import { forwardRef } from "react";

const ScheduleDemoForm = forwardRef(function ScheduleDemoForm(props, ref) {
  const { title, formFields, formik } = props;
  const { primary } = useThemePalette();
  return (
    <AppContainer sx={{ py: 5, width: { xs: "100%", md: "88%" } }} gap={3}>
      <Title text={title} sx={{ mx: "auto" }} />
      <form onSubmit={formik.handleSubmit}>
        <Stack bgcolor={primary} alignItems="start" gap={3} p={5} ref={ref}>
          <Stack
            direction={{ xs: "column", md: "row" }}
            flexWrap="wrap"
            gap={3}
            justifyContent="space-between "
            sx={{ mx: "auto" }}
          >
            {formFields.map((field, index) => (
              <FormField
                key={index}
                field={field}
                name={field.name}
                label={field.label}
                width={field.width}
                formik={formik}
              />
            ))}
          </Stack>
          <GradientBtn title="Send" type="submit" sx={{ px: 7 }} />
        </Stack>
      </form>
    </AppContainer>
  );
});

export default ScheduleDemoForm;
