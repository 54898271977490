import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { motion } from "framer-motion";
import Title from "components/Molecules/Section/Title";
import AppContainer from "components/Atoms/Container/AppContainer";
import Body from "components/Molecules/Section/Body";
import TalkToExpertBtn from "components/Atoms/Buttons/TalkToExpertBtn";
import { images } from "assets/AssetHelper";
import VerificationCard from "components/Molecules/Cards/VerificationCard";

const Verification = ({ title, body, collapse, initialLength, cardData , sx}) => {
  return (
    <AppContainer sx={{ alignItems: "center", pb: 4 }}>
      <Stack
        direction={{ xs: "column", md: "row" }}
        sx={{
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "32px",
        }}
      >
        <Stack
          sx={{
            width: { xs: "100%", md: "45%" },
            alignItems: { xs: "center", md: "flex-start" },
            textAlign: { xs: "center", md: "start" },
            gap:"32px"
          }}
        >
          <Title
            text={title}
            sx={{
              textAlign: { xs: "center", md: "start" },
            }}
          />
          <Body
            text={body}
            sx={{
              textAlign: { xs: "center", md: "start" },
              width: { xs: "100%", md: "569px" },
            }}
            collapse={collapse}
            initialLength={initialLength}
          />
          <TalkToExpertBtn />
        </Stack>

        <Stack
          sx={{
            width: { xs: "100%", md: "55%" },
            alignItems: "center",
          }}
        >
          <motion.div
            whileInView={{ x: [0, 0], y: [2, -10], scale: [0, 0.85] }}
            transition={{
              ease: "easeOut",
              duration: 1,
              delay: 0.1,
              type: "spring",
              stiffness: 100,
            }}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box component="img" src={images.Verification} width="100%" />
          </motion.div>
        </Stack>
      </Stack>

      {/* Cards Section */}
      <Stack
        direction={{xs: "column", md: "row" }}
        sx={{
          width: { xs: "100%", md: "80%" },
          alignItems: "center",
          gap:"38px"
        }}
      >
        {cardData.map((card, index) => (
          <VerificationCard key={index} value={card.value} label={card.label} />
        ))}
      </Stack>
    </AppContainer>
  );
};

export default Verification;
