import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { ResalaBaseURL, ResalaPassword, ResalaUsername } from "services/modules/utils/constant";
import VoicePricingTemplate from "templates/pricing/voice/VoicePricing.Template";
import { formFields, initialValues, validationSchema } from "./form";
import Loader from "components/Atoms/Loader/Loader";

export default function VoicePricingPage({ size }) {
  const [isLoading, setIsLoading] = useState(false);
  const [rawPrices, setRawPrices] = useState();
  const [country, setCountry] = useState("");
  const basicAuthToken = btoa(`${ResalaUsername}:${ResalaPassword}`);

  const countries = Array.from(new Set(rawPrices?.map((item) => item.cscd))).filter((item) => item);
  const ppm = rawPrices?.find((item) => item.cscd == country)?.rate1;

  const formik = useFormik({
    initialValues,
    validationSchema,
    // onSubmit: handleSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const getPrices = () => {
    setIsLoading(true);
    const apiUrl = `${ResalaBaseURL}/voice_rate?product_id=237`;

    fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Basic ${basicAuthToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setIsLoading(false);
        setRawPrices(data);
      })
      .catch((error) => {
        // Handle errors
        setIsLoading(false);
        toast.error(error, { position: "top-center" });
      });
  };
  const prices = [
    {
      title: "Domectic Calling (PSTN)",
      children: [
        { title: "Call a landline number", ppm, unit: "minute" },
        { title: "Call a mobile number", ppm, unit: "minute" },
        { title: "Recieve a call", ppm, unit: "minute" },
      ],
    },
    {
      title: "International Calling (PSTN)",
      children: [
        { title: "Call a landline number", ppm, unit: "minute" },
        { title: "Call a mobile number", ppm, unit: "minute" },
        { title: "Recieve a call", ppm, unit: "minute" },
      ],
    },
    {
      title: "Internet Calling",
      children: [
        { title: "Make/receive a call you SIP endpoint", ppm, unit: "minute" },
        { title: "Webscoket call", ppm, unit: "minute" },
        { title: "Make/recrive a call to APP(WebRTC)", ppm, unit: "minute" },
      ],
    },
    {
      title: "Toll Free Calling (PSTN)",
      children: [
        { title: "Recieve a call", ppm, unit: "minute" },
        { title: "Webscoket call", ppm, unit: "minute" },
      ],
    },
  ];

  useEffect(() => {
    getPrices();
  }, []);

  useEffect(() => {
    if (countries?.length) {
      setCountry(countries[0]);
    }
  }, [rawPrices]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Loader open={isLoading} />
      <VoicePricingTemplate
        size={size}
        formik={formik}
        formFields={formFields}
        prices={prices}
        countries={countries}
        country={country}
        setCountry={setCountry}
      />
    </>
  );
}
