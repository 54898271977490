import { useThemePalette } from "common/hooks/theme_palette";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Typography from "@mui/material/Typography";
import { StepConnector } from "@mui/material";
import { Box } from "@mui/system";

export default function CustomStepper({ activeStep, handleClick, steps, sx }) {
  const { darkGray } = useThemePalette();
  const border = "2px solid #68D6BA";
  return (
    <Box sx={{ maxWidth: 600, py: 2, ...sx }}>
      <Stepper activeStep={activeStep} orientation="vertical" sx={{}}>
        {steps.map((step, index) => (
          <Step
            key={step.label}
            sx={{
              ".MuiStepContent-root": {
                borderLeft: border,
              },
              ".MuiStepContent-last": {
                borderLeft: activeStep == index ? border : "none",
              },
              ".MuiStepConnector-line": {
                borderLeft: index != steps.length - 1 || activeStep == index ? border : "none",
              },
            }}
          >
            <StepLabel
              icon={
                <Box
                  sx={{
                    width: "24px",
                    height: "24px",
                    borderRadius: "50%",
                    ml: "2px",
                    background: "linear-gradient(128deg, #68D6BA 18.75%, #E7A1C0 86.92%)",
                    cursor: "pointer",
                  }}
                />
              }
              onClick={() => handleClick(index)}
            >
              <Typography
                sx={{
                  fontFamily: "Alef",
                  fontWeight: 700,
                  fontSize: "18px",
                  ml: 2,
                  color: "black",
                  cursor: "pointer",
                }}
              >
                {step.label}
              </Typography>
            </StepLabel>
            <StepConnector sx={{ py: 1, mt: -2 }} />
            <StepContent
              sx={{
                mt: -2,
                mb: -4,
                py: 2,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Alef",
                  fontSize: "16px",
                  color: darkGray,
                  ml: { xs: 2, sm: 3 },
                  mt: -3,
                  lineHeight: { xs: "27px", sm: "37px" },
                }}
              >
                {step.description}
              </Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
