import { Stack, Typography } from "@mui/material";
import Header from "components/Organisms/page-header";
import { useThemePalette } from "common/hooks/theme_palette";
import Section from "components/Organisms/section/Section";
// import Services from "components/Organisms/services/voice/Services";
import Services from "components/Organisms/services/voice/services";
// import Voip from "components/Organisms/services/voice/Voip";
import Voip from "components/Organisms/services/voice/voip";
// import UseCases from "components/Organisms/services/voice/UseCases";
import UseCases from "components/Organisms/services/voice/useCases";
import NewsLetter from "components/Molecules/Footer/NewsLetter";

export default function VoiceTemplate({ voiceServices, voiceUseCases, size }) {
  const { primary, white } = useThemePalette();

  return (
    <>
      <Stack flexDirection={"column"} gap={3}>
        <Header
          title="Voice Enterprise"
          subTitle="Elevate Customer Interactions"
          body={
            <div>
              <Typography sx={{mt:"16px"}} fontWeight={700} fontSize={"22px"} fontFamily={"Alef"} color={"#2D2D2D"}>
                Elevate Customer Interactions
              </Typography>
              <Typography  sx={{mt:"10px", width:"95%"}} fontWeight={400} fontSize={"18px"} fontFamily={"Alef"}>
                Enhance your customer experience with RESALAs programmable Voice API. Integrate
                user context into WebRTC and PSTN calls for personalized interactions. Innovate and
                scale with our flexible voice capabilities, backed by a reliable global carrier
                network and per-second billing
              </Typography>
            </div>
          }
          sx={{ mt: "115px" }}
          isActions
        />

        <Section title={"Advanced Voice Solutions by RESALA"} bgcolor={primary} />
        <Services data={voiceServices} size={size} />

        <Voip
          title="Expansive VoIP Network"
          body={
            <Typography fontSize={{ md: "20px", xs: "16px" }}>
              At <span style={{ fontWeight: "bold" }}>RESALA</span>, we understand the vital
              importance of voice communication in today&apos;s interconnected world. Through
              strategic partnerships with top telecom companies, we have developed a robust and
              resilient VoIP network that spans borders, providing unmatched connectivity for our
              partners.
            </Typography>
          }
        />
        <Section title={"Voice Enterprise Use Cases"} bgcolor={white} />
        <UseCases data={voiceUseCases} size={size} />
      </Stack>
      <NewsLetter />
    </>
  );
}
