import { images } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";
import Header from "components/Organisms/page-header";
import Introduction from "components/Organisms/services/SMS/promotional/Introduction";
import Slider from "components/Organisms/services/SMS/reminder/Slider";
import WhyReminders from "components/Organisms/services/SMS/reminder/WhyReminders";
import Advantages from "components/Organisms/services/SMS/transactional/Advantages";
import NewsLetter from "components/Molecules/Footer/NewsLetter";
import "./slider.styles.css";
export default function RemindersTemplate({ remindersSectors, size, advantageOfReminders }) {
  const { primary } = useThemePalette();

  const text = (
    <>
      A dependable way to maintain customer engagement and keep them updated throughout their
      journey. With 6.65 billion mobile phones globally and a 98% open rate, your business can
      confidently ensure that all reminders via text message gain the visibility they need, securely
      and on a large scale. <br /> Send reminders to anyone, anywhere, and at any time over the
      Resala platform, employing automated workflows designed to fit your business requirements.
      With rapid delivery, worldwide reach, and local compliance, your text message reminders will
      stand out among other business communications.
    </>
  );

  return (
    <>
      <Header
        title="Reminders"
        body=" Ensure your clients never miss important appointments or dates. Begin sending text.
        message reminders without the need for any coding."
        isActions
      />
      <Introduction
        bgcolor={primary}
        body={text.props.children}
        image={images.Reminder}
        collapse
        initialLength={size >= 900 ? 250 : 100}
        sx={{ flexDirection: { xs: "column-reverse", md: "row-reverse" }, alignItems: "center" }}
        isAnimationLeft
      />
      <WhyReminders
        title="When should SMS reminders be sent?"
        body={ 
          <>
            SMS reminders should be dispatched at the right time to reach the right customers. <br /> The optimal time for sending reminders varies based on your industry, clientele, and the types of reminders you deliver. With real-time analytics,
            we can assist your business in determining the perfect moment to distribute a variety of reminder text messages.
          </>
        }
        />
      <Slider header="SMS Reminders for Every Sector" data={remindersSectors} />
      <Advantages
        title="The Advantages of Sending Text Message Reminders"
        body="Including text message reminders in your communication strategy aids in streamlining processes with easy integration and automation, thereby enhancing engagement."
        advantageOfReminders={advantageOfReminders}
      />
      <NewsLetter />      

    </>
  );
}
