import { images } from "assets/AssetHelper";
import Header from "components/Organisms/page-header";
import Definition from "components/Organisms/services/SMS/transactional/Definition";
import Varieties from "components/Organisms/services/SMS/transactional/Varieties";
import NewsLetter from "components/Molecules/Footer/NewsLetter";
export default function TransactionalTemplate({ size, transactionalSMSVarieties }) {
  return (
    <>
      <Header
        title="Transactional"
        body="Boost trust in your brand by confirming customer activities with notification messages."
        isActions
      />
      <Definition
        title="Transactional SMS"
        body="Explore transactional text messages and their potential to improve your customers' experiences."
        image={images.Definition}
        subTitle="Defining Transactional SMS"
        body2="Ideal for sharing essential and valuable information about your products or services with your
        customers, transactional messages are of great utility. When using text messages, businesses
        must ensure these informational messages gain the visibility required to make an impact.
        Around 5 billion people globally can receive text messages. 90% of individuals read text
        messages within 3 minutes of reception. By automating transactional messages through text,
        you can provide superior customer service. Make sure your customers are always up-to-date
        and never miss crucial information by leveraging the most trusted communication channel on
        the most robust platform."
        collapse
        initialLength={size >= 900 ? 260 : 150}
      />
      <Varieties
        title="Transactional SMS: Varieties"
        body="Suitable for any industry, transactional text messages are a perfect choice. Thanks to automation options enabling prompt and appropriately timed dispatch, your customers will receive necessary updates at the right moments."
        transactionalSMSVarieties={transactionalSMSVarieties}
      />
      <NewsLetter />      

    </>
  );
}
