import { Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";

export default function SliderCard({ card, isCentered }) {
  const { textColor } = useThemePalette();

  const sliderCardStyle = {
    border: " double 8px transparent",
    borderRadius: "20px",
    backgroundImage:
      "linear-gradient(white, white), radial-gradient(circle at 50% -40%, #89EDCF 40%, #EB98BC)",
    backgroundOrigin: "border-box",
    backgroundClip: "padding-box, border-box",
    minHeight: { xs: "500px", sm: "400px", lg: "490px" },
    textAlign: "center",
    mx: "auto",
    p: 1.5,
    scale: isCentered ? "0.9" : "0.72",
    width: "100%",
    transition: "all 0.3s ease",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 10px;",
  };

  return (
    <Stack sx={sliderCardStyle} className="slider-card">
      <Typography
        sx={{
          background: `linear-gradient(120deg, #E9AFC8 40%, #96F2DA 65%)`,
          WebkitBackgroundClip: "text",
          color: "transparent",
          display: "inline",
          fontSize: { xs: "50px", sm: "60px" },
          fontWeight: 700,
          fontFamily: "Alumni Sans",
        }}
      >
        {card.id < 10 ? `0${card.id}` : card.id}
      </Typography>
      <Stack gap={3}>
        <Typography fontSize={{ xs: "26px", sm: "30px" }} fontFamily="Alumni Sans" fontWeight={700}>
          {card?.title}
        </Typography>
        <Typography
          fontSize={{ xs: "14px", sm: "16px" }}
          fontFamily="Alef"
          fontWeight={400}
          lineHeight="31px"
          color={textColor}
        >
          {card?.body}
        </Typography>
      </Stack>
    </Stack>
  );
}
