import { icons } from "assets/AssetHelper";
import { useEffect } from "react";
import TransactionalTemplate from "templates/services/SMS/transactional/Transactional.Template";

export default function TransactionalPage({ size }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const transactionalSMSVarieties = [
    {
      title: "Retail and E-commerce",
      body: [
        "Greetings messages",
        "Purchase confirmation texts",
        "Updates on shipment and delivery",
        "Banking and Finance",
      ],
      icon: icons.ShoppingCart,
    },
    {
      title: "Banking information verification",
      body: ["Payment acknowledgement", "Confirmations or cancellations of appointments", "Travel"],
      icon: icons.Currency,
    },
    {
      title: "Confirmation of bookings  ",
      body: ["Alterations in gate", "Flight delays", "Telecommunications"],
      icon: icons.Calendar,
    },
    {
      title: "Password reset acknowledgment",
      body: ["Confirmation of bill payment", "Account validation", "Healthcare"],
      icon: icons.Lock,
    },

    {
      title: "Confirmation of appointments via text",
      body: ["Insurance payment acknowledgment", "Confirmation for prescription collection"],
      icon: icons.Appointment,
    },
  ];

  return (
    <TransactionalTemplate size={size} transactionalSMSVarieties={transactionalSMSVarieties} />
  );
}
