import {
  Box,
  List,
  ListItem,
  ListItemButton,
  Stack,
  ListItemText,
  Drawer,
  Divider,
  Button,
} from "@mui/material";
import { images } from "assets/AssetHelper";
import TalkToExpertBtn from "components/Atoms/Buttons/TalkToExpertBtn";
import { NavLink } from "react-router-dom";

export default function NavDrawer({
  mainNavItems,
  secondaryNavItems,
  handleDrawerToggle,
  mobileOpen,
  drawerWidth,
  pathname,
  handleNavLinkClicked,
}) {
  const flattenNavLinks = (navItems) => {
    let result = [];

    function flatten(obj) {
      result.push(obj);

      if (obj.children && obj.children.length > 0) {
        obj.children.forEach(flatten);
      }
    }

    navItems.forEach(flatten);

    return result.filter((elem) => elem?.path);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle}>
      <Stack
        sx={{
          py: 5,
        }}
        direction={"row"}
        justifyContent="center"
      >
        <Box component="img" src={images.Logo} width={{ xs: "158px", sm: "238px" }} />
      </Stack>
      <List>
        {flattenNavLinks(mainNavItems).map((item, i) => (
          <ListItem
            key={i}
            disablePadding
            sx={{
              ".active": {
                backgroundColor: "rgba(104, 214, 186, 0.50)",
                fontWeight: 700,
              },
              ".active .MuiTypography-root": {
                fontWeight: 700,
              },
              ".MuiTypography-root": {
                fontFamily: "Alef",
                fontSize: "16px",
                py: 1,
              },
            }}
          >
            <NavLink to={item?.path} style={{ width: "100%", fontWeight: 700 }}>
              <ListItemButton>
                <ListItemText primary={item.label} />
              </ListItemButton>
              {item.path !== pathname && <Divider width="90%" sx={{ mx: "auto" }} />}
            </NavLink>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <nav>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", lg: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
      >
        {drawer}

        <Stack sx={{ mt: "auto" }} gap={3}>
          <Stack direction="row">
            {secondaryNavItems.map((item, index) => (
              <Stack
                key={index}
                sx={{
                  borderRight: index !== secondaryNavItems.length - 1 && "2px solid black",
                  px: 1,
                  pr: index !== secondaryNavItems.length - 1 && 2,
                  height: "30px",
                }}
              >
                <Button
                  onClick={() => {
                    handleNavLinkClicked(item.path, item.isExternal);
                    handleDrawerToggle();
                  }}
                  sx={{
                    color: "#000",
                    fontSize: "20px",
                    fontWeight: 700,
                    fontFamily: "Alegreya Sans",
                    height: "100%",
                    m: 0,
                    pb: 1,
                  }}
                >
                  {item.label}
                </Button>
              </Stack>
            ))}
          </Stack>
          <TalkToExpertBtn sx={{ ml: 2, fontSize: "20px", width: "fit-content", px: 5 }} />
        </Stack>
      </Drawer>
    </nav>
  );
}
