import { icons, images } from "assets/AssetHelper";
import { useEffect } from "react";
import VirtualNumbersTemplate from "templates/services/VirtualNumbers/VirtualNumbers.Template";

export default function VirtualNumbersPage({ size }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const numbers = [
    {
      id: 1,
      label: "Local DIDs",
      title: "Verified Sender",
      body: "Research shows that customers are more likely to answer calls when they see a prefix they recognize. Local numbers place your business within the community",
      content: [
        "DID numbers are linked to specific geographic areas.",
        "Incoming calls are billed at regular local rates.",
        "Ideal for individuals and local businesses.",
      ],
      image: images.LocalDID,
    },
    {
      id: 2,
      label: "Mobile DIDs",
      title: "Boost Response Rates",
      body:"Using mobile numbers to call leads increases answer rates and sales potential. Two-way communication also enhances engagement.",
      content: [
        "Numbers are linked to a country and may not be region-specific.",
        "DID phone numbers support two-way SMS.",
        "Ideal for businesses that rely on SMS communication.",
      ],
      image: images.MobileDID,
    },
    {
      id: 3,
      label: "Toll-Free DIDs",
      title: "Enhance Brand Credibility",
      body:"Research shows that customers are more likely to answer calls when they see a prefix they recognize. Local numbers place your business within the community",
      content: [
        "Free for the caller.",
        "Available as local or global toll-free numbers.",
        "Ideal for handling high volumes of incoming calls.",
      ],
      image: images.TollDID,
    },
    {
      id: 4,
      label: "Non-Geographical DIDs",
      title: "Grow Your Business Nationwide",
      body: "Non-geographical DID numbers aren't tied to a specific location, giving your business a national presence and building trust.",
      content: [
        "Not linked to a specific region.",
        "Reachable nationwide at the same cost.",
        "Perfect for national agencies to collect payments and information.",
      ],
      image: images.GeoDID,
    },
  
  ];

  const features = [
    {
      id: 1,
      icon: icons.Setup,
      header: (
        <>
         Quick Setup
        </>
      ),
      info: (
        <>
        Get your local DID number up and running with just a few clicks.
        </>
      ),
    },
    {
      id: 2,
      icon: icons.TwoWay,
      header: (
        <>
          Two-Way 
          Communication
        </>
      ),
      info: (
        <>
          Get your local DID number up and running with just a few clicks.
        </>
      ),
    },
    {
      id: 3,
      icon: icons.Versatile,
      header: (
        <>
          Versatile Functions
        </>
      ),
      info: (
        <>
        Supports SMS, fax, and international calls.
      </>
      ),
    },
    {
      id: 4,
      icon: icons.Channels,
      header: (
        <>
          Multiple Channels
        </>
      ),
      info: (
        <>
          All DIDs come with multiple channels; additional ones can be added.
        </>
      ),
    },
    {
      id: 5,
      icon: icons.Management,
      header: (
        <>
          Easy Management
        </>
      ),
      info: (
        <>
        Use our user-friendly web portal to manage and purchase virtual DIDs.
      </>
      ),
    },

    {
      id: 6,
      icon: icons.Analytics,
      header: (
        <>
          Detailed Analytics
        </>
      ),
      info: (
        <>
          Track metrics such as destination, status, and monthly costs.
        </>
      ),
    },

  ];


  const extraFeatures = [
    {
      id: 1,
      icon: icons.TechSupport,
      header: (
        <>
          Technical support <br/> 24/7
        </>
      ),
    },
    {
      id: 2,
      icon: icons.PortabilityAvailability,
      header: (
        <>
          Portability available
        </>
      ),
    },
    {
      id: 3,
      icon: icons.PSTNreplacement,
      header: (
        <>
          PSTN replacement
        </>
      ),
    },
    {
      id: 4,
      icon: icons.EmergencyServices,
      header: (
        <>
          Emergency services <br/> calling available

        </>
      ),
    },
    {
      id: 5,
      icon: icons.MonitorDID,
      header: (
        <>
          Monitor your DID <br/> and outbound traffic

        </>
      ),
    },
    {
      id: 6,
      icon: icons.APIavailable,
      header: (
        <>
          API available

        </>
      ),
    },

  ];


  return <VirtualNumbersTemplate numbers={numbers} features={features} extraFeatures={extraFeatures}  size={size} />;
}
