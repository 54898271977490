import PromotionalTemplate from "templates/services/SMS/promotional/Promotional.Template";
import { images } from "assets/AssetHelper";
import { useEffect } from "react";

export default function PromotionalPage({ size }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const steps = [
    {
      id: 1,
      label: "Engagement",
      description:
        "Offer new customers a warm welcome with a special deal. Get their attention and pique their interest in discovering more about your offerings from the get-go.",
      img: images.Engagement,
    },
    {
      id: 2,
      label: "Evaluation",
      description:
        "Based on their browsing behaviors, send promotional text messages about items that may be of interest to your potential customers. With relevant deals and information in your marketing texts, you'll increase the likelihood of enticing them to finalize their purchase.",
      img: images.Evaluation,
    },
    {
      id: 3,
      label: "Support",
      description:
        "Provide customers with a promotional text that offers free shipping on their next order upon completion of a feedback form about their experience with your brand. This will enable you to gather invaluable data while leaving customers pleased with a bonus gift",
      img: images.Support,
    },
    {
      id: 4,
      label: "Retention",
      description:
        "Show customers that they matter by sending personalized messages. Birthday messages with a promo code usable during their birthday month will make them feel valued and motivate them to return for more purchases.",
      img: images.Retention,
    },
  ];

  const strategies = [  
    {
      id: 1,
      label: "Customized",
      content:
        "Demonstrate to your customers that you know them beyond their first names. Tailor your messages with consideration of the recipient and what they might find appealing. If you're seeking to stimulate a purchase via promotional text, ensure you're offering deals or promoting items that align with their past browsing or purchasing behavior.",
      image: images.Strategy1,
    },
    {
      id: 2,
      label: "Encouraging customer action",
      content:
        "Include a call-to-action in every promotional text message. Invite customers to visit your website for new products, click on a link to grab a discount, or check out a list of items where they can apply their new promo code. Providing customers with immediate access to your exclusive deals through text messages creates a hassle-free way to enjoy the advantages of customer loyalty",
      image: images.Strategy2,
    },
    {
      id: 3,
      label: "Brand-associated texts",
      content:
        "Help customers feel secure while interacting via text messages. When your promotional texts are branded, you convey assurance that these messages are from a reliable brand, not an impersonal bot. This approach also allows your brand's unique personality to make a memorable impression in their text inbox",
      image: images.Strategy3,
    },
    {
      id: 4,
      label: "Short and Direct",
      content:
        "Deliver your message directly and succinctly, providing all the essential information within 160 characters or even fewer. Since customers' time is precious, short, compelling messages are the key to successful promotional text campaigns.",
      image: images.Strategy4,
    },
  ];
  return <PromotionalTemplate steps={steps} strategies={strategies} size={size} />;
}
