import { Box, Stack } from "@mui/material";
import AppContainer from "components/Atoms/Container/AppContainer";
import Body from "components/Molecules/Section/Body";
import Title from "components/Molecules/Section/Title";

export default function Section({ title, body, collapse, initialLength, bgcolor, icon, sx }) {
  return (
    <Stack sx={{ py: 2, bgcolor: bgcolor }}>
      <AppContainer sx={{ textAlign: "center", gap: 3 }}>
        <Stack direction="row" alignItems="center" justifyContent="center" gap={3}>
          <Box component="img" src={icon} />
          <Title text={title} />
        </Stack>
        {body && <Body text={body} collapse={collapse} initialLength={initialLength} />}
      </AppContainer>
    </Stack>
  );
}
