import { Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import AppContainer from "components/Atoms/Container/AppContainer";

const CustomText = ({ text }) => {
  const { textColor } = useThemePalette();
  return (
    <Typography sx={{ color: textColor, fontSize: "16px", fontFamily: "Alef", fontWeight: 700 }}>
      {text}
    </Typography>
  );
};
export default function Content() {
  return (
    <AppContainer gap={4}>
      <CustomText text="1. INFORMATION GATHERED BY RESALA.IO" />
      <CustomText text="This is RESALA.IO's online privacy policy (“Policy”). This policy is specific to activities conducted on the RESALA.IO website and does not extend to offline or unrelated activities." />
      <CustomText text="RESALA.IO collects anonymous data to enhance website performance. This information, such as browser type and visit duration, is used for website improvement and does not personally identify users. Additionally, users may voluntarily provide personally identifiable information, including name, address, telephone number, and email address." />
      <CustomText text="2. USE AND DISCLOSURE OF INFORMATION" />
      <CustomText text="Unless stated otherwise, RESALA.IO does not sell, trade, or rent personally identifiable information collected on the site. The collected information is utilized for order processing, order status updates, notifying users of relevant products or offers, and statistical purposes to enhance the website." />
      <CustomText text="3. COOKIES" />
      <CustomText text="RESALA.IO uses cookies to determine previous visits to the home page without gathering other user information." />
      <CustomText text="4. SECURITY" />
      <CustomText text="RESALA employs appropriate measures for data privacy and security. However, the security of disclosed information online cannot be guaranteed." />
      <CustomText text="5.OTHER WEBSITES" />
      <CustomText text="RESALA.IO is not responsible for the privacy policies of linked websites. Users are advised to review the privacy policies of third-party sites before providing any data." />
      <CustomText text="6. MINORS" />
      <CustomText text="RESALA.IO does not knowingly collect personal information from minors under 18. Minors are not permitted to use the RESALA.IO website or services." />
      <CustomText text="7. CORRECTIONS AND UPDATES" />
      <CustomText text="Users can modify or update information received by contacting [specified email address]." />
      <CustomText text="8. MODIFICATIONS OF THE PRIVACY POLICY" />
      <CustomText text="The RESALA.IO Privacy Policy may be updated periodically. Users are encouraged to visit the relevant sections for updates, with modifications taking effect on the day they are posted." />
      <CustomText text="9. Log Information" />
      <CustomText text=" We automatically receive information from your web browser or mobile device. This information includes the name of the website from which you entered our website, if any, as well as the name of the website to which you're headed when you leave our website. This information also includes the IP address of your computer/proxy server that you use to access the internet, your internet service provider name, web browser type, type of mobile device, and computer operating system. We use all of this information to analyze trends among our users to help improve our website." />
      <CustomText text="10. Information Regarding Your Data Protection Rights Under General Data Protection Regulation (GDPR)" />
      <CustomText text="For the purpose of this Privacy Policy, Resala is a Data Controller of your personal information." />
      <CustomText text="If you are from the European Economic Area (EEA), our legal basis for collecting and using your personal information, as described in this Privacy Policy, depends on the information we collect and the specific context in which we collect it. We may process your personal information because:" />
      <CustomText text="We need to perform a contract with you, such as when you use our services." />
      <CustomText text="You have given us permission to do so." />
      <CustomText text="The processing is in our legitimate interests and it's not overridden by your rights." />
      <CustomText text="For payment processing purposes." />
      <CustomText text="To comply with the law." />
      <CustomText text="If you are a resident of the European Economic Area (EEA), you have certain data protection rights. In certain circumstances, you have the following data protection rights:" />
      <CustomText text="The right to access, update, or delete the personal information we have on you" />
      <CustomText text="The right of rectification." />
      <CustomText text="The right to object." />
      <CustomText text="The right of restriction." />
      <CustomText text="The right to data portability." />
      <CustomText text="The right to withdraw consent." />
      <CustomText text="Please note that we may ask you to verify your identity before responding to such requests." />
      <CustomText text="You have the right to complain to a Data Protection Authority about our collection and use of your personal information. For more information, please contact your local data protection authority in the European Economic Area (EEA)." />
      <CustomText text="11. Do Not Sell My Personal Information Notice for California Consumers Under California Consumer Privacy Act (CCPA)" />
      <CustomText text="Under the CCPA, California consumers have the right to:" />
      <CustomText text="Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers." />
      <CustomText text="Request that a business delete any personal data about the consumer that a business has collected." />
      <CustomText text="Request that a business that sells a consumer's personal data not to sell the consumer's personal data." />
      <CustomText text="If you make a request, we have one month to respond to you. If you wish to exercise any of these rights, please contact us." />
    </AppContainer>
  );
}
//
//
//
//
//
//
// .
//
//
//
//
//
//
//
//
//
//
//
//
