import { Stack } from "@mui/system";
import AppContainer from "components/Atoms/Container/AppContainer";
import Body from "components/Molecules/Section/Body";
import Title from "components/Molecules/Section/Title";
import React, { useState } from "react";
import CustomStepper from "components/Molecules/Stepper/CustomStepper";

export default function Advantages({ title, body, advantageOfReminders }) {
  const [activeStep, setActiveStep] = useState(0);

  const handleClick = (index) => {
    setActiveStep(index);
  };

  return (
    <AppContainer sx={{ py: 8 }}>
      <Stack sx={{ textAlign: "center", gap: 3 }}>
        <Title text={title} />
        <Body text={body} />
      </Stack>
      {/* <C steps={advantageOfReminders} /> */}
      <CustomStepper
        activeStep={activeStep}
        handleClick={handleClick}
        steps={advantageOfReminders}
        sx={{maxWidth:'100%'}}
      />
    </AppContainer>
  );
}

