import { Box, Stack } from "@mui/material";
import { images } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";
import GradientBtn from "components/Atoms/Buttons/GradientBtn";
import AppContainer from "components/Atoms/Container/AppContainer";
import Body from "components/Molecules/Section/Body";
import Title from "components/Molecules/Section/Title";

export default function Demo({ title, body, image, btnContent, onClick }) {
  const { primary } = useThemePalette();
  return (
    <Stack
      sx={{
        bgcolor: primary,
        py: 2,
        backgroundImage: {
          xs: `url(${images.WaveBackground})`,
          md: `${`linear-gradient(90deg, ${primary} 30%,rgba(0,0,0,0)  80%)`},url(${
            images.WaveBackground
          })`,
        },
        backgroundRepeat: "no-repeat",
        backgroundSize: { xs: "100% 30%", md: "100% 90%" },
        backgroundPosition: { xs: "50% 70%", md: "-20% -20%" },
        minHeight: { xs: "auto", md: "440px", lg:"380px" },
      }}
    >
      <AppContainer direction={{ xs: "column", md: "row" }} gap={5}>
        {/* ---------------- CONTENT ---------------- */}
        <Stack sx={{ textAlign: { xs: "center", md: "start" }, width:{xs:'100%', md:'60%'} }} gap={3}>
          <Title text={title} />
          <Body
            text={body}
            sx={{
              textAlign: { xs: "center", md: "start" },
            }}
          />
          <GradientBtn
            title={btnContent}
            onClick={onClick}
            sx={{
              width: "fit-content",
              mx: { xs: "auto", md: "0" },
            }}
          />
        </Stack>
        {/* ---------------- IMAGE ---------------- */}
        <Stack
          width="80%"
          sx={{ mt: { xs: 0, md: -12, lg: -15 }, mx: "auto" }}
          alignItems={{ xs: "center", md: "end" }}
        >
          <Box
            component="img"
            src={image}
            width={{ xs: "90%", sm: "50%", md: "50%", lg: "35%", xl: "26%" }}
          />
        </Stack>
      </AppContainer>
    </Stack>
  );
}
