import { Box, Stack, Typography } from "@mui/material";
import { images } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";
import AppContainer from "components/Atoms/Container/AppContainer";
import Body from "components/Molecules/Section/Body";
import Title from "components/Molecules/Section/Title";
import { motion } from "framer-motion";

export default function VoiceService({ title, body }) {
  const { white } = useThemePalette();
  return (
    <Stack sx={{ backgroundColor: white }}>
      <AppContainer
        direction={{ xs: "column-reverse", md: "row" }}
        gap={{ xs: 5, md: 5 }}
        py={5}
        alignItems="center"
        justifyContent="center"
      >
        <Stack
          direction={{ xs: "column-reverse", md: "row" }}
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            gap: 5,
            width: { xs: "100%", md: "75%" },
          }}
        >
          {/* IMAGE */}
          <Stack sx={{ overflow: "hidden" }} width={{ xs: "100%", md: "70%", lg: "30%" }}>
            <motion.div
              whileInView={{ x: [-200, 0] }}
              transition={{
                ease: "easeOut",
                duration: 1,
                delay: 0.1,
                type: "spring",
                stiffness: 100,
              }}
            >
              <Box
                component="img"
                src={images.VoiceService}
                sx={{
                  width: { xs: "100%", sm: "100%", md: "90%", lg: "100%" }, // Responsive width
                  maxWidth: "100%", // Ensure it doesn't exceed container width
                  height: "100%", // Maintain aspect ratio
                }}
              />
            </motion.div>
          </Stack>
          {/* CCONTENT */}
          <Stack gap={{ xs: 3, md: 5 }} sx={{ width: "70%" }}>
            <Title
              text={title}
              sx={{
                textAlign: { xs: "center", md: "start" },
              }}
            />
            <Body
              text={body}
              sx={{
                textAlign: { xs: "center", md: "start" },
                width: { md: "fit-content", xs: "100%" },
              }}
            />
          </Stack>
        </Stack>
      </AppContainer>
    </Stack>
  );
}
