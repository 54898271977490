import { Stack } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import AppContainer from "components/Atoms/Container/AppContainer";
import CustomSelect from "components/Atoms/Select/CustomSelect";
import Body from "components/Molecules/Section/Body";
import Title from "components/Molecules/Section/Title";

export default function VoicePricing({ title, body, countries, country, setCountry }) {
  const { primary, secondary } = useThemePalette();

  const handleChange = (e) => {
    const value = e.target.value;
    setCountry(value);
  };

  return (
    <Stack bgcolor={primary}>
      <AppContainer gap={4} py={2}>
        <Stack sx={{ gap: 5 }}>
          <Title text={title} sx={{ textAlign: { xs: "center", md: "center" } }} />
          <Body
            text={body}
            sx={{
              textAlign: { xs: "center", md: "center" },
              fontWeight: 400,
            }}
          />
        </Stack>
        <Stack direction={{ xs: "column", md: "row" }} gap={2} justifyContent={"center"}>
          <CustomSelect
            label="Country"
            value={country || ""}
            name="country"
            handleChange={handleChange}
            options={countries}
            width={{ xs: "100%", md: "25%" }}
            sx={{
              fontFamily: "Alef",
              fontWeight: 700,
              fontSize: "16px",
              backgroundColor: secondary,
            }}
          />
        </Stack>
      </AppContainer>
    </Stack>
  );
}
