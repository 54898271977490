import NewsLetter from "components/Molecules/Footer/NewsLetter";
import Header from "components/Organisms/page-header";
import DownloadPricing from "components/Organisms/pricing/DownloadPricing";
import PricingCards from "components/Organisms/pricing/PricingCards";
import VoicePricing from "components/Organisms/pricing/VoicePricing";
export default function VoicePricingTemplate({
  size,
  formFields,
  formik,
  prices,
  countries,
  country,
  setCountry,
}) {
  return (
    <>
      <Header
        title="Voice pricing"
        body="Resala Voice pricing is pay-per-use with no hidden fees, billed by the second. Explore our rates for SIP connections"
        collapse={size <= 900}
        initialLength={70}
      />

      <VoicePricing
        title="Voice pricing"
        body="Discover RESALA's Voice pricing"
        countries={countries}
        country={country}
        setCountry={setCountry}
      />
      <PricingCards prices={prices} />
      {/* <DownloadPricing
        title="Download Voice pricing for all 190+  countries."
        formFields={formFields}
        formik={formik}
      /> */}
      <NewsLetter />
    </>
  );
}
