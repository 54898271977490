import { Stack } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import AppContainer from "components/Atoms/Container/AppContainer";
import Body from "components/Molecules/Section/Body";
import Title from "components/Molecules/Section/Title";

export default function Incorporating({ title, body, collapse }) {
  const { primary } = useThemePalette();
  return (
    <Stack>
      <AppContainer gap={5} py={4}>
        <Title text={title} sx={{ textAlign: "center" }} />
        <Body text={body} collapse={collapse} sx={{ width: "100%" }} />
      </AppContainer>
    </Stack>
  );
}
