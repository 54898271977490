import { Box, Stack, Typography,Grid } from "@mui/material";
import AppContainer from "components/Atoms/Container/AppContainer";
import Body from "components/Molecules/Section/Body";
import { motion } from "framer-motion";

export default function UseCases({ data, size }) {
  return (
    <AppContainer sx={{ py: 5, gap: 5, px: { xs: 0, lg: 15 } }}>
      <Grid container spacing={3} justifyContent="center" alignItems="stretch">
        {data.map((elem, i) => (
          
          <Grid 
            item 
            xs={6} 
            md={3} 
            key={i}
            sx={{ mb: 3 }} container
          >
                      <motion.div
          initial="hidden"
          whileInView="visible"
          // viewport={{ once: true }}
          transition={{ duration: 0.7 }}
          whileHover={{ scale: 1.2 }}
          variants={{
            visible: { opacity: 1, scale: 1 },
            hidden: { opacity: 0, scale: 0 }
          }}
        >

            <Grid container direction="column" alignItems="center" justifyContent={"stretch"} spacing={3} >
              <Grid item>
                    <Box
                component="img"
                src={elem.icon}
                width={{ xs: "50px", md: "76px" }}
                sx={{
                    left: { xs: "42%", sm: "45%", md: "35%", lg: "35%", lg: "40%", xl: "42%" },
                    top: { xs: "-13%", lg: "-15%" },
                }}
                />
              </Grid>

              <Grid item >
                <Typography sx={{ fontFamily: "Alef", fontWeight: 700, fontSize: { xs: "14px", sm: "16px" } }}>
                  {elem.title}
                </Typography>
              </Grid>

              <Grid item>
                <Body
                  text={elem.body}
                  sx={{
                    fontFamily: "Alef",
                    fontSize: "16px",
                    textAlign: { xs: "center", md: "center" },
                  }}

                />
              </Grid>
            </Grid>

            </motion.div>
          </Grid>
        ))}
      </Grid>
    </AppContainer>
  );
}
