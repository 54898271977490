import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import HomeTemplate from "templates/home/Home.Template";

const HomePage = ({ setLayoutData }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const cards = [
    {
      id: 1,
      title: "GDPR Complinace Enterpise solutions",
      body: "In strict compliance with the General Data Protection Regulation (GDPR), we are dedicated to upholding robust measures to ensure data privacy and security in customer communications. Our approach emphasizes transparency, aligning seamlessly with GDPR provisions to safeguard the confidentiality and integrity of personal information.",
    },
    {
      id: 2,
      title: "Worldwide Delivery in Under 10 Seconds",
      body: "Experience rapid message delivery for time-critical information with our proactive monitoring system. We guarantee quick delivery, even for high-volume messages, and constantly measuredelivery rates and speed through live testing and reporting.",
    },
    {
      id: 3,
      title: "Direct Connections to Mobile Operators",
      body: "Take advantage of our extensive network, which connects to over 600 mobile networks across 200+ countries. This ensures that you can benefit from high-quality routes at the most competitive prices.",
    },
    {
      id: 4,
      title: "Secure, Reliable, and 24/7/365 Support",
      body: "Rely on our highly secure and ISO 27001 certified platform with a redundant setup, offering exceptional reliability and uptime. Our dedicated Network Operations Centre provides round-the-clock support, ensuring uninterrupted service.",
    },
    {
      id: 5,
      title: "Real-time Analytics and Comprehensive Insights",
      body: "Leverage our powerful analytic tools to gain a detailed and up-to-date overview of your worldwide traffic. This valuable insight enables you to track, trace, and optimize message flows, empowering you to make data-driven decisions and maximize the effectiveness of your communication strategies.",
    },
  ];

  const FAQQuestions = [
    {
      id: 1,
      title: "Which countries does RESALA cover for SMS services?",
      body: "RESALA facilitates message delivery to all countries except North Korea.",
    },
    {
      id: 2,
      title: "What is Voice API?",
      body: "A voice API is a tool that allows your web application to initiate and receive phone calls worldwide using the internet. It connects to the Public Switched Telephone Network (PSTN) for calls to any device globally,or via VoIP for internet-based calls to connected applications. Integrating a voice API is quick and simple, requiring no dedicated telecommunications hardware for voice call management.",
    },
    {
      id: 3,
      title: "What is Voice calling app?",
      body: "A voice calling app is a software application that enables users to initiate and receive phone calls. It connects users to both traditional telephone networks (PSTN) and VoIP services, facilitating communication across various devices and locations.These apps typically offer additional features such as call transcription, voice recording, interactive voice response (IVR),SIP integration, text-to-speech, and more.",
    },





    // {
    //   id: 2,
    //   title: "What's the maximum length for a sender name?",
    //   body: "A unique sender name is limited to 11 alphanumeric characters.",
    // },
    // {
    //   id: 3,
    //   title: "Can the character limit for a sender name be extended?",
    //   body: "Unfortunately, the limit is constrained by the technical capabilities of the GSM standard, allowing 11 characters.",
    // },
    // {
    //   id: 4,
    //   title: "Can I receive messages from customers?",
    //   body: "Yes! Two-way SMS communication relies on Virtual Mobile Numbers, enabling both sending and receiving messages from customers.",
    // },
    // {
    //   id: 5,
    //   title: "Is RESALA using imported databases?",
    //   body: (
    //     <>
    //       No. RESALA upholds data safety and privacy, adhering to GDPR regulations.{" "}
    //       <NavLink style={{ color: "#414141", textDecoration: "underline" }} to="/privacy-policy">
    //         Refer to our Privacy Policy
    //       </NavLink>{" "}
    //       for further details.
    //     </>
    //   ),
    // },

    {
      id: 4,
      title: "Can I use services without a written agreement?",
      body: "Absolutely! RESALA operates on a prepaid basis, accepting credit card or bank transfer payments. Postpaid agreements are available for monthly message volumes exceeding 100,000.",
    },
    // {
    //   id: 5,
    //   title: "How long are my funds valid?",
    //   body: "Transferred funds are available indefinitely.",
    // },
    {
      id: 6,
      title: "Is there a payment required for account registration?",
      body: (
        <>
          No, sign-up is free of charge.{" "}
          <NavLink style={{ color: "#414141", textDecoration: "underline" }} to="">
            Test our services without additional payments.
          </NavLink>
        </>
      ),
    },
    {
      id: 7,
      title: "Why might certain sender names be rejected?",
      body: "Certain sender names may be denied due to security concerns. Names linked to well-known brands require confirmation of usage rights.",
    },
    {
      id: 8,
      title: "Can adult content and spam be sent via RESALA?",
      body: "Absolutely not.",
    },
    {
      id: 9,
      title: "Is the 2 WAY SMS feature available in every country?",
      body: "Unfortunately, no. Availability is subject to local regulations and Virtual Mobile Number availability.",
    },
    // {
    //   id: 10,
    //   title: "Is RESALA involved in buying or selling contact databases?",
    //   body: "No, RESALA will never sell or buy contact databases.",
    // },
    // {
    //   id: 11,
    //   title: "How can I use WhatsApp with RESALA?",
    //   body: "Utilize WhatsApp for proactive templated notifications or provide 24/7 customer support with chatbots and live agents.",
    // },

    // {
    //   id: 12,
    //   title: "What is RESALA WhatsApp Business API pricing?",
    //   body: "WhatsApp Business API prices are customized to individual needs. Connect with our WhatsApp Business API experts for personalized offers.",
    // },
    // {
    //   id: 13,
    //   title:
    //     "What's the difference between WhatsApp Business App and WhatsApp Business Platform at RESALA?",
    //   body: "WhatsApp Business API differs from the free WhatsApp Business App. Consult our WhatsApp experts to determine the best fit for your business.",
    // },
  ];
  return <HomeTemplate cards={cards} FAQQuestions={FAQQuestions} />;
};

export default HomePage;
