import { Box, Stack } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import { motion } from "framer-motion";
import AppContainer from "components/Atoms/Container/AppContainer";
import Title from "components/Molecules/Section/Title";
import Body from "components/Molecules/Section/Body";

export default function Introduction({
  title,
  body,
  otherChildren,
  image,
  collapse,
  titleStyle,
  bgcolor,
  sx,
  isAnimationLeft = false,
  initialLength,
  position = "right", // Default position
}) {
  const { primary } = useThemePalette();

  return (
    <Stack bgcolor={bgcolor}>
      <AppContainer
        alignItems="center"
        justifyContent="space-between"
        sx={{ pb: 3, gap: 5, flexDirection: { xs: "column", md: "row" }, ...sx }}
      >
        {/* Conditional rendering based on position */}
        <Stack
          width={{ xs: "90%", sm: "75%", md: "45%", lg: "30%", xl: "35%", overflow: "hidden" }}
          order={position === "left" ? { xs: 2, md: 1 } : { xs: 1, md: 2 }}
        >
          {position === "left" && (
            <motion.div
              whileInView={{ opacity: [0, 1], x: isAnimationLeft ? [-100, 0] : [100, 0] }}
              transition={{
                ease: "easeOut",
                duration: 2,
                delay: 0.1,
                type: "spring",
                stiffness: 100,
              }}
            >
              <Box component="img" src={image} width="95%" />
            </motion.div>
          )}
          {position === "right" && (
            <motion.div
              whileInView={{ opacity: [0, 1], x: isAnimationLeft ? [-100, 0] : [100, 0] }}
              transition={{
                ease: "easeOut",
                duration: 2,
                delay: 0.1,
                type: "spring",
                stiffness: 100,
              }}
            >
              <Box component="img" src={image} width="95%" />
            </motion.div>
          )}
        </Stack>

        <Stack
          sx={{ width: { xs: "100%", md: "57%" }, textAlign: { xs: "center", md: "start" } }}
          gap={3}
          order={position === "left" ? { xs: 1, md: 2 } : { xs: 2, md: 1 }}
        >
          <Title text={title} sx={titleStyle} />
          <Body
            text={body}
            sx={{ textAlign: { xs: "center", md: "start" } }}
            collapse={collapse}
            initialLength={initialLength}
          />
          {otherChildren && otherChildren}
        </Stack>
      </AppContainer>
    </Stack>
  );
}
