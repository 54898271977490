import { Stack, Typography, Box } from "@mui/material";
import { images } from "assets/AssetHelper";
import Header from "components/Organisms/page-header";
import { useThemePalette } from "common/hooks/theme_palette";
import Section from "components/Organisms/section/Section";
import VoiceService from "components/Organisms/services/wholesales/VoiceService";
import WhyResala from "components/Organisms/services/wholesales/WhyResala";
import NewsLetter from "components/Molecules/Footer/NewsLetter";
import MessagingServices from "components/Organisms/services/wholesales/MessagingServices";

export default function WholeSalesTemplate({ wholeSalesServices,resalaReasons,size }) {
  const { primary, white } = useThemePalette();

  return (
    <>
      <Header
        title="Wholesales SMS | Voice  "
        sx={{ mt: '10px' }}        
      />

      <Section 
        title={"Comprehensive Solutions for MNOs and MVNOs"}
        body={
          <Box maxWidth="80%" mx="auto" textAlign="center">
            <Typography fontSize={{ md: '18px', xs: '16px' }}>
              At <span style={{ fontWeight: 'bold' }}>RESALA</span>, we deliver a broad spectrum of innovative solutions tailored for Mobile Network Operators (MNOs) and Mobile Virtual Network Operators (MVNOs). With a robust track record and collaboration with over 300 operator networks worldwide, we are your trusted partner for an array of essential services and solutions.
            </Typography>
          </Box>
        }
        bgcolor={primary}
      />

      <VoiceService
        title={"Voice services"}
        body={
          <Typography fontSize={{ md: '18px', xs: '16px' }}>
            <span style={{ fontWeight: 'bold' }}>RESALA</span> stands out as a premier global voice provider. Our extensive network spans 200 countries, supported by strategic alliances with international and regional telecom operators. We are committed to delivering top-tier service and support, employing the latest technologies to enhance every aspect of our offerings. Our voice services ensure seamless, high-quality communication through a reliable and innovative infrastructure.
          </Typography>
        }
      />

      <Section 
        title={"MESSAGING SERVICES"}
        body={
          <Box maxWidth="80%" mx="auto" textAlign="center">
            <Typography fontSize={{ md: '18px', xs: '16px' }} >
              At <span style={{ fontWeight: 'bold', color: "black" }}>RESALA</span>, we understand the vital importance of voice communication in today&apos;s interconnected world. Through strategic partnerships with top telecom companies, we have developed a robust and resilient VoIP network that spans borders, providing unmatched connectivity for our partners.
            </Typography>
          </Box>
        }
        bgcolor={primary}
      />
      <MessagingServices data={wholeSalesServices} size={size} />

      <Section 
        title={"SECURITY AND SUPPORT"}
        body={
          <Box maxWidth="80%" mx="auto" textAlign="center">
            <Typography fontSize={{ md: '18px', xs: '16px' }}>
              At <span style={{ fontWeight: 'bold' }}>RESALA</span>, security is paramount. We utilize advanced technologies to protect our messaging operations, and our team of experts ensures that all services operate securely and efficiently. Our dedicated support team is always available to assist and ensure seamless service delivery.
            </Typography>
          </Box>
        }
        bgcolor={primary}
      />

      <WhyResala title={"WHY PARTNER WITH RESALA?"} data={resalaReasons} />

      <NewsLetter 
        text={"At"} 
        gradientText={"RESALA"} 
        text2={<>, we offer innovative solutions to enhance communication for MNOs and MVNOs.<br/> Partner with us for unmatched service and support</>} 
      />      
    </>
  );
}
