import { countries } from "countries-list";
import * as Yup from "yup";

export const formFields = [
  { label: "First Name", type: "text", name: "firstName" },
  { label: "Last Name", type: "text", name: "lastName" },
  { label: "Work Email", type: "text", name: "email", width: "100%" },
];

export const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
};

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
  lastName: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
});
