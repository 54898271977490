import { Stack, Typography, Grid, Divider } from "@mui/material";
import AppContainer from "components/Atoms/Container/AppContainer";
import GradientBorderedCard from "components/Molecules/Cards/GradientBorderedCard";

export default function PricingCards({ prices }) {
  return (
    <AppContainer sx={{ py: 5, pb: 10 }} gap={7}>
      <Stack
        direction="row"
        flexWrap="wrap"
        justifyContent="center"
        sx={{ rowGap: "24px", columnGap: "40px" }}
      >
        {prices?.map((item, i) => (
          <GradientBorderedCard
            key={i}
            cardsx={{
              alignItems: "start",
              width: { xs: "100%", md: "40%" },
              boxShadow: "none",
              padding: "0px",
              position: "relative", 
              overflow: "hidden",
              gap: 0,
            }}
            animated={false}
          >
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                width: "100%",
                backgroundColor: "#E6FAF6",
                padding: "12px 16px",
                borderRadius: "16px 16px 0 0",
                gap: 2, 
              }}
            >
              <img
                src={item.icon}
                alt=""
                style={{
                  width: '24px',
                  height: 'auto',
                }}
              />
              <Typography
                fontFamily="Alumni Sans"
                fontWeight={600}
                fontSize={{ xs: "20px", sm: "28px" }}
              >
                {item.title}
              </Typography>
            </Stack>

            <Grid container spacing={1} alignItems="center" justifyContent="center" sx={{ p: 2, mb:5 }}>
              {item?.children?.map((child, idx) => (
                <Grid item key={idx} xs={12}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{ textAlign: "left" }}
                  >
                    <Typography
                      color="#3C3C3CCC"
                      fontFamily="Alef"
                      fontWeight={700}
                      fontSize={{ xs: "14px", sm: "16px" }}
                      sx={{ flex: 1, wordBreak: "break-word" }}
                    >
                      {child.title}
                    </Typography>
                    <Stack direction="column" sx={{ flexShrink: 0 }}>
                      <Typography
                        fontFamily="Alef"
                        fontWeight={700}
                        fontSize={{ xs: "14px", sm: "16px" }}
                        color="#DB5F93"
                      >
                        ${child.ppm}
                      </Typography>
                      <Typography
                        fontFamily="Alef"
                        fontWeight={700}
                        fontSize={{ xs: "14px", sm: "16px" }}
                      >
                        per {child.unit}
                      </Typography>
                    </Stack>
                  </Stack>

                  {idx < item.children.length - 1 && (
                    <Divider sx={{ my: 1, backgroundColor: "#B3B3B3" }} />
                  )}
                </Grid>
              ))}

              {item.extraText && (
                <Typography
                  color="#272727"
                  fontFamily="Alef"
                  fontWeight={700}
                  fontSize={{ xs: "14px", sm: "18px" }}
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    padding: "16px",
                    margin: 0,
                    textAlign: "left", 
                  }}
                >
                  {item.extraText}
                </Typography>
              )}
            </Grid>
          </GradientBorderedCard>
        ))}
      </Stack>
    </AppContainer>
  );
}