import { Box, Stack, Typography,Grid } from "@mui/material";
import AppContainer from "components/Atoms/Container/AppContainer";
import Body from "components/Molecules/Section/Body";
import { motion } from "framer-motion";

export default function Services({ data, size }) {
  return (
    <AppContainer sx={{ py: 5, gap: 5, px: { xs: 0, lg: 15 } }}>
      <Grid container spacing={3} justifyContent="center" alignItems="stretch">
        {data.map((elem, i) => (
          <Grid 
            item 
            xs={12} 
            md={6} 
            key={i}
            sx={{ mb: 3 }}
          >
            <motion.div
            initial="hidden"
            whileInView="visible"
            // viewport={{ once: true }}
            transition={{ duration: 0.7 }}
            whileHover={{ scale: 1.2 }}
            variants={{
              visible: { opacity: 1, scale: 1 },
              hidden: { opacity: 0, scale: 0 }
            }}
            >

              <Stack flexDirection="row" alignItems="center" justifyContent={"center"}  >
                    <Box
                      component="img"
                      src={elem.image}
                      width={{ xs: "45%", md: "25%" }}
                      sx={{ display: "block", mx: "auto" }}
                    />

                <Stack flexDirection={"column"} gap={3} width={{ xs: "50%", md: "60%" }} alignItems={{md:'start',xs:'center'}}>
                  <Typography sx={{ fontFamily: "Alef", fontWeight: 700, fontSize: { xs: "14px", sm: "16px" } ,textAlign: { xs: "center", md: "start" }}}>
                    {elem.title}
                  </Typography>

                  <Body
                    text={elem.body}
                    sx={{
                      fontFamily: "Alef",
                      fontSize: { xs: "12px", sm: "16px" } ,
                      textAlign: { xs: "center", md: "start" },
                    }}

                  />

                </Stack>
              </Stack>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </AppContainer>
  );
}
