import { Stack } from "@mui/material";
import AppContainer from "components/Atoms/Container/AppContainer";
import Title from "components/Molecules/Section/Title";
import CommunicationSolutionsCard from "components/Molecules/Cards/CommunicationSolutionsCard";

export default function CommunicationSolutions({ title, communicationSolutions }) {
  return (
    <AppContainer sx={{ py: 10, gap: { xs: 10, sm: 4 } }}>
      <Title text={title} sx={{ mx: "auto", textAlign: "center" }} />
      <Stack gap={{ xs: 15, md: 5 }} direction={{ xs: "column", md: "row" }} flexWrap="wrap">
        {communicationSolutions.map((item) => (
          <CommunicationSolutionsCard key={item.id} card={item} />
        ))}
      </Stack>
    </AppContainer>
  );
}
