import * as React from "react";
import { AppBar, Box, CssBaseline, IconButton, Toolbar, Stack, Button } from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import { icons, images } from "assets/AssetHelper";
import { useLocation, useNavigate } from "react-router-dom";
import NavDrawer from "./NavDrawer";
import NavLinks from "./NavLinks";
import GradientBtn from "components/Atoms/Buttons/GradientBtn";
import { useEffect } from "react";
import AppContainer from "components/Atoms/Container/AppContainer";

const drawerWidth = 320;
const navItems = [
  { label: "Home", path: "/", type: "normal" },
  {
    label: "Services",
    path: "",
    type: "",
    children: [
      {
        label: "SMS Enterprise",
        path: "",
        type: "",
        icon: icons.SMS,
        children: [
          {
            label: "Promotional",
            path: "/sms/promotional",
            type: "normal",
            icon: icons.Promotional,
          },
          {
            label: "Authentication",
            path: "/sms/authentication",
            type: "normal",
            icon: icons.Authentication,
          },
          {
            label: "Transactional",
            path: "/sms/transactional",
            type: "normal",
            icon: icons.Transactional,
          },
          {
            label: "Reminders",
            path: "/sms/reminders",
            type: "normal",
            icon: icons.Reminders,
          },
          {
            label: "Notifications",
            path: "/sms/notifications",
            type: "normal",
            icon: icons.Notifications,
          },
        ],
      },
      {
        label: "RCS",
        path: "/RCS",
        type: "normal",
        icon: icons.RCS,
      },
      {
        label: "Voice Enterprise",
        path: "/voice-enterprise",
        type: "normal",
        icon: icons.Voice,
      },
      {
        label: "Virtual Numbers",
        path: "/virtual-numbers",
        type: "normal",
        icon: icons.DID,
      },
      {
        label: "Wholesales SMS / Voice",
        path: "/wholesales",
        type: "normal",
        icon: icons.Wholesales,
      },
      // {
      //   label: "WhatsApp",
      //   path: "/whatsapp",
      //   type: "normal",
      //   icon: icons.Whatsapp,
      // },
    ],
  },
  {
    label: "Pricing",
    path: "",
    type: "",
    children: [
      {
        label: "SMS",
        path: "/pricing/sms",
        type: "normal",
        icon: icons.SMS,
      },
      {
        label: "Voice",
        path: "/pricing/voice",
        type: "normal",
        icon: icons.Voice,
      },
      {
        label: "Numbers",
        path: "/pricing/numbers",
        type: "normal",
        icon: icons.DID,
      },
    ],
  },

  // { label: "Pricing", path: "/pricing", type: "normal" },
  { label: "About Us", path: "/about-us", type: "normal" },
  { label: "Careers", path: "/careers", type: "normal" },
  { label: "Support", path: "/support", type: "normal" },
  { label: "Login", path: "https://retail.resala.io/", type: "normal", isExternal: true },
];

const mainNavItems = navItems.slice(0, 5);
const secondaryNavItems = navItems.slice(5);

const Header = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [activePath, setActivePath] = React.useState(null);

  // Sub-Menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentMenu, setCurrentMenu] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event, menu) => {
    setAnchorEl(event.currentTarget);
    setCurrentMenu(menu);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentMenu(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const isActive = (path) => {
    return path === activePath;
  };

  const handleNavLinkClicked = (path, isExternal = false) => {
    isExternal ? window.open(path) : navigate(path);
    !isActive(path) && setActivePath(path);
  };

  useEffect(() => {
    setActivePath(pathname);
  }, [pathname]);

  const navLinkStyle = (isActive) => {
    return {
      color: "#000",
      fontSize: "16px",
      mx: 1,
      fontWeight: isActive && 700,
      "&::after": {
        content: '""',
        position: "absolute",
        bottom: 0,
        left: 0,
        height: "2px",
        backgroundImage: "linear-gradient(94deg, #94F3DA 7.22%, #EDABC7 97.75%)",
        transition: "width 0.3s ease-in-out",
        width: isActive && "50%",
        borderRadius: "10px",
        ml: "17px",
      },
    };
  };

  return (
    <Box
      sx={{
        display: "flex",
        position: "sticky",
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: "#fff",
        zIndex: 1201,
      }}
    >
      <CssBaseline />
      <AppContainer>
        <Stack
          component="nav"
          sx={{
            backgroundColor: "transparent",
            // py: 2,
            boxShadow: "none",
            width: "100%",
            // justifyContent: "center",
            position: "relative",
          }}
          direction="row"
          alignItems="center"
        >
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              mr: 2,
              display: { lg: "none" },
              color: "#000",
              fontWeight: "700",
            }}
          >
            <MenuIcon />
          </IconButton>
          {/* LOGO */}
          <Stack
            sx={{
              flexGrow: { xs: 0, md: 1.5 },
              display: { sm: "block", cursor: "pointer" },
              ml: { xs: "25px", sm: 0 },
            }}
            direction={"row"}
            justifyContent="center"
            onClick={(_) => handleNavLinkClicked("/")}
          >
            <Box component="img" src={images.Logo} width={{ xs: "158px", md: "186px" }} />
          </Stack>
          {/* NAV LINKS START*/}
          <NavLinks
            mainNavItems={mainNavItems}
            secondaryNavItems={secondaryNavItems}
            navLinkStyle={navLinkStyle}
            isActive={isActive}
            handleNavLinkClicked={handleNavLinkClicked}
            open={open}
            handleClick={handleClick}
            handleClose={handleClose}
            anchorEl={anchorEl}
            setActivePath={setActivePath}
            currentMenu={currentMenu}
          />
          {/* NAV LINKS END*/}
        </Stack>
      </AppContainer>
      {/* DRAWER */}
      <NavDrawer
        handleDrawerToggle={handleDrawerToggle}
        handleNavLinkClicked={handleNavLinkClicked}
        navItems={navItems}
        mainNavItems={mainNavItems}
        secondaryNavItems={secondaryNavItems}
        mobileOpen={mobileOpen}
        drawerWidth={drawerWidth}
        pathname={pathname}
      />
    </Box>
  );
};

export default Header;
