import { images } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";
import AppContainer from "components/Atoms/Container/AppContainer";
import Header from "components/Organisms/page-header";
import Section from "components/Organisms/section/Section";
import Reasons from "components/Organisms/services/SMS/notifications/Reasons";
import Introduction from "components/Organisms/services/SMS/promotional/Introduction";
import Slider from "components/Organisms/services/SMS/reminder/Slider";
import NewsLetter from "components/Molecules/Footer/NewsLetter";
export default function NotificationsTemplate({
  size,
  reasonsToUseNotifications,
  utilizingNotifications,
}) {
  const { primary,white} = useThemePalette();
  return (
    <>
      <Header
        title="Notifications"
        body={
          <>
            Instantly notify customers for enhanced marketing and customer service messaging. <br />
            With SMS notifications, you can quickly update and alert customers, ensuring your
            critical messages are highly visible.
          </>
        }
        isActions
      />
      <Introduction
        image={images.SMSNotifications}
        title="What are SMS Notifications?"
        body="SMS notifications enable businesses to update their customers about schedule alterations,
        order modifications, emergency warnings, upcoming events, or any time-sensitive information
        the customer needs to be aware of. They are excellent for promotional marketing messages and
        support updates. Regardless of the use case, SMS notifications enable greater reach and
        engagement with your customers"
        collapse
        initialLength={size >= 900 ? 200 : 100}
        sx={{ alignItems: "center" }}
        bgcolor={primary}
      />
            <Section
        bgcolor={white}
      />

      <Section
        bgcolor={primary}
        title="Why Should You Use SMS Notifications?"
        body="SMS is quick and convenient. As every mobile device can receive SMS messages, it's the ideal channel for sending notifications, keeping customers always informed."
      />
      <Reasons data={reasonsToUseNotifications} size={size} />
      <Section
        bgcolor={primary}
        title="SMS Notifications: The back-up plan"
        body="you utilize other channels to communicate with customers, be sure to include SMS as a backup channel. SMS notifications don't rely on data or Wi-Fi availability, so when messages can't be sent over chat apps due to poor service, they can be rerouted to SMS. This is ideal for time-critical messages and vital updates that customers need to know about as they occur. Avoid having undelivered messages and keep your customers content with backup SMS notifications."
        collapse={size <= 900}
      />
      <AppContainer sx={{ width: { xs: "100%", md: "80%" } }}>
        <Slider
          sx={{ bgcolor: "white" }}
          header="How Various Industries Utilize SMS Notifications"
          data={utilizingNotifications}
          isDotsLeft={false}
          // listStyle={{
          // display: "flex",
          // flexWrap: "wrap",
          // columnGap: 10,
          // flexDirection: { xs: "row", md: "column" },
          // height: { xs: "auto", md: "100px" },
          // }}
        />
      </AppContainer>
      <NewsLetter />      

    </>
  );
}
