import { Box, Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import AppContainer from "components/Atoms/Container/AppContainer";
import Body from "components/Molecules/Section/Body";
import Title from "components/Molecules/Section/Title";
import { motion } from "framer-motion";

export default function AuthenticationVarieties({ title, body, image, authVarieties }) {
  const {  fadeGray } = useThemePalette();

  return (
    <Stack >
      <AppContainer sx={{ py: 5, gap: 3 }}>
        <Title text={title} sx={{ textAlign: { xs: "center", md: "start" } }} />
        <Body
          text={body}
          sx={{ textAlign: { xs: "center", md: "start" }, width: "100%", fontSize: "16px" }}
        />
        <Stack
          direction={{ xs: "column-reverse", md: "row" }}
          alignItems="center"
          gap={{ xs: 5, md: 20 }}
        >
          <Stack sx={{ width: { xs: "80%", md: "20%" }, overflow: "hidden" }}>
            <motion.div
              whileInView={{ opacity: [0, 1], x: [-100, 0] }}
              transition={{
                ease: "easeOut",
                duration: 2,
                delay: 0.1,
                type: "spring",
                stiffness: 100,
              }}
            >
              <Box component="img" src={image} width="100%" />
            </motion.div>
          </Stack>

          <Stack gap={3}>
            {authVarieties.map((item, i) => (
              <Stack key={i} direction="row" alignItems="center" gap={2}>
                <Stack
                  sx={{
                    borderRadius: "50%",
                    width: "24px",
                    height: "24px",
                    fontSize: { xs: "16px", md: "15px" },
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#fff",
                    background:
                      "linear-gradient(71deg, rgba(104,214,186,1) 0%, rgba(231,161,192,1) 65%)",
                  }}
                >
                  <Typography fontFamily="Alumni Sans" fontSize="20px" fontWeight={700}>
                    {"0" + `${i + 1}`}
                  </Typography>
                </Stack>
                <Typography fontFamily="Alef" fontSize="16px" color={fadeGray}>
                  {item}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </AppContainer>
    </Stack>
  );
}
