import { List, ListItem, Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";

export default function CommonPasswordMistakes({ passwordMistakes, size }) {
  const { darkGray } = useThemePalette();

  return (
    <Stack sx={{ mt: -3 }}>
      <List sx={{ listStyleType: { xs: "none", md: "disc" }, textAlign: "center" }}>
        {passwordMistakes.map((mistake, i) => (
          <ListItem
            key={i}
            sx={{
              display: { xs: "block", md: "list-item" },
              textAlign: { xs: "center", md: "start" },
              fontSize: { xs: "16px", sm: "20px" },
              lineHeight: { xs: "16px", md: "43px" },
              color: darkGray,
              alignItems: "start",
              justifyContent: "center",
            }}
          >
            {size <= 900 && <span style={{ marginRight: "5px" }}>.</span>}
            {mistake}
          </ListItem>
        ))}
      </List>
      <Typography
        sx={{
          fontFamily: "Alef",
          fontWeight: 700,
          fontSize: { xs: "16px", sm: "22px" },
          color: "#000",
        }}
      >
        These errors expose individuals to the risk of fraudsters and hackers accessing their data
        and information.
      </Typography>
    </Stack>
  );
}
