import { Button, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import { useState } from "react";

export default function Body({ text, isImage, sx, collapse = false, initialLength = 245 }) {
  const { darkGray, fadeBlack, fadeGray } = useThemePalette();
  const [isExpanded, setIsExpanded] = useState(false);

  const content = !collapse
    ? text
    : isExpanded
    ? text
    : text.toString().slice(0, initialLength).concat("..");

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Typography
      fontFamily="Alef"
      fontSize={{ xs: "16px", sm: "18px" }}
      fontWeight={400}
      lineHeight={{ xs: "21px", md: "32px" }}
      color={fadeGray}
      sx={{ mt: -2, textAlign: { xs: "center", sm: isImage ? "start" : "center" }, ...sx }}
    >
      {content}
      {collapse && (
        <Button
          sx={{
            fontSize: { xs: "16px", sm: "18px" },
            fontWeight: 700,
            fontFamily: "Alef",
            color: "black",
            p: 0,
            ml: 1,
          }}
          onClick={handleToggle}
        >
          {isExpanded ? "Show Less" : "Show More"}
        </Button>
      )}
    </Typography>
  );
}
