import { Button } from "@mui/material";
import React from "react";

export default function GradientBtn({ title = "click me", sx, onClick, ...otherProps }) {
  return (
    <Button
      onClick={onClick}
      sx={{
        background: "linear-gradient(94deg, #68D6BA 7.22%, #EC9EC0 97.75%)",
        borderRadius: "48px",
        border: 0,
        color: "white",
        height: 48,
        padding: "0px 30px",
        fontFamily: "Alumni Sans",
        fontSize: "26px",
        fontWeight: 700,
        whiteSpace: "nowrap",
        width: { xs: "auto", md: 'fit-content' },
        ...sx,
      }}
      {...otherProps}
    >
      {title}
    </Button>
  );
}
