import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useThemePalette } from "common/hooks/theme_palette";
import { useTheme } from "@mui/material";

export default function SimpleAccordion({ title, body }) {
  const { white } = useThemePalette();
  const theme = useTheme(); 

  return (
    <Accordion
      sx={{
        width: { xs: "100%", sm: "100%" },
        boxShadow: "none",
        backgroundColor: white,
        color: "black",
        // borderRadius: "5px", // Optional: Add some border radius
      }}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            fontSize="medium"
            sx={{
              color: "black", // Use contrast color for the icon
            }}
          />
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          flexDirection: "row-reverse", // Icon on the left
          alignItems: "center",
          width: '100%', 
          '& .MuiAccordionSummary-content': {
            m: 0,
          },
        }}
      >
        <Typography
          fontFamily="Alef"
          fontWeight={700}
          fontSize={{ xs: "18px", sm: "20px", lg: "18px" }}
          sx={{ p: 0, m: 0 }} // Ensure Typography has no padding or margin
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 2 }}>
        {body && body}
      </AccordionDetails>
    </Accordion>
  );
}
