import { Box, Stack } from "@mui/material";
import { images } from "assets/AssetHelper";
export default function SectionBackground({ children, bgImage = images.WaveBackground, sx }) {
  return (
    <Stack
      sx={{
        backgroundImage: `url(${bgImage})`,
        minHeight: { xs: "auto", sm: "auto" },
        backgroundRepeat: "no-repeat",
        backgroundSize: { xs: "100% 40%", sm: "100% 100%" },
        backgroundPosition: "center",
        ...sx,
      }}
    >
      {children}
    </Stack>
  );
}
