import AppContainer from "components/Atoms/Container/AppContainer";
import Body from "components/Molecules/Section/Body";
import Title from "components/Molecules/Section/Title";

export default function WhyReminders({ title, body }) {
  return (
    <AppContainer sx={{ py: 7, gap: "32px" , textAlign:'center'}}>
      <Title text={title} />
      <Body text={body} />
    </AppContainer>
  );
}
