import { Box, Stack } from "@mui/material";
import AppContainer from "components/Atoms/Container/AppContainer";
import Body from "components/Molecules/Section/Body";
import Title from "components/Molecules/Section/Title";
import {  images } from "assets/AssetHelper";
import { motion } from "framer-motion";

export default function OurPartners({title,body}) {
  return (
    <Stack >
      <AppContainer
        direction={{ xs: "column", md: "column" }}
        gap={{ xs: 5, md: 20 }}
        py={5}
        alignItems="center"
        textAlign="center"
      >


          <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          variants={{
            visible: { opacity: 1, scale: 1 },
            hidden: { opacity: 0, scale: 0 }
          }}
          style={{
            display: "flex",
            justifyContent: "center",
            direction: "row",
          }}
        >

        <Stack gap={{ xs: 3, md: 5 }} py={2} alignItems={"center"}>
          <Title text={title}  sx={{ textAlign: "center",fontWeight: 600,fontSize: { xs: "30px", sm: "40px" },fontFamily: "Alumni Sans" }}/>
          <Body text={body} sx={{ textAlign: "center",fontWeight: 700,fontSize: { xs: "17px", sm: "27px" },fontFamily: "Alef"  }} />
          <Box component={'img'} src={images.Partners}
            sx={{
              width: { xs: "100%", sm: "100%", md: "90%", lg: "90%" }, // Responsive width
              maxWidth: "100%", // Ensure it doesn't exceed container width
              height: "auto", // Maintain aspect ratio
            }}
          />
        </Stack>


        </motion.div>

      </AppContainer>
    </Stack>
  );
}

