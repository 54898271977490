import { Box, Stack } from "@mui/material";
import { images } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";
import GradientBtn from "components/Atoms/Buttons/GradientBtn";
import AppContainer from "components/Atoms/Container/AppContainer";
import FormField from "components/Atoms/Input/FormInput/FormField";
import Body from "components/Molecules/Section/Body";
import Title from "components/Molecules/Section/Title";
import React from "react";

export default function ContactSupportForm({ title, body, formFields, formik }) {
  const { primary } = useThemePalette();

  return (
    <AppContainer gap={2} sx={{ width: { xs: "100%", md: "60%" }, mb: 5 }}>
      <Title text={title} sx={{ mx: "auto" }} />
      <Body text={body} />
      <form onSubmit={formik.handleSubmit}>
        <Stack bgcolor={primary} alignItems="start" gap={3} p={5} sx={{ position: "relative" }}>
          <Stack
            direction={{ xs: "column", md: "row" }}
            flexWrap="wrap"
            gap={5}
            justifyContent="space-between "
            sx={{ mx: "auto" }}
          >
            {formFields.map((field, index) => (
              <React.Fragment key={index}>
                <FormField field={field} formik={formik} />
                {index === formFields.length - 2 && (
                  <Box
                    component="img"
                    src={images.ContactSupport}
                    width="380px"
                    sx={{
                      position: "absolute",
                      right: 30,
                      bottom: 40,
                      display: { xs: "none", md: "block" },
                    }}
                  />
                )}
              </React.Fragment>
            ))}
          </Stack>
          <GradientBtn title="Send" type="submit" sx={{ px: 7, mx: { xs: "auto", md: 0 } }} />
          <Box
            component="img"
            src={images.ContactSupport}
            width="100%"
            sx={{
              right: 50,
              bottom: 50,
              display: { xs: "block", md: "none" },
            }}
          />
        </Stack>
      </form>
    </AppContainer>
  );
}
