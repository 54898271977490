import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import emailjs from "emailjs-com";

import { EmailJSPublicKey, EmailJSServiceID } from "services/modules/utils/constant";
import { formFields, initialValues, validationSchema } from "./form";
import SupportTemplate from "templates/support/Support.Template";
import Loader from "components/Atoms/Loader/Loader";

export default function SupportPage() {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = (values, { resetForm }) => {
    console.log(values);
    sendEmail(values);
    resetForm();
  };

  const sendEmail = (values) => {
    setIsLoading(true);
    emailjs.send(EmailJSServiceID, "contact_support", values, EmailJSPublicKey).then(
      (result) => {
        setIsLoading(false);
        toast.success("form sent", { position: "top-center" });
      },
      (error) => {
        setIsLoading(false);
        toast.error("form failed", { position: "top-center" });
      }
    );
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  });
  return (
    <>
      <Loader open={isLoading} />
      <SupportTemplate formik={formik} formFields={formFields} />
    </>
  );
}
