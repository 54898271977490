import Header from "components/Organisms/page-header";
import EarthModel from "components/Molecules/Home/EarthModel";
import Slider from "components/Organisms/home/Slider";
import SendSMS from "components/Organisms/home/SendSMS";
import FAQ from "components/Organisms/home/FAQ";
import OurPartners from "components/Organisms/home/OurPartners";
import NewsLetter from "components/Molecules/Footer/NewsLetter";
import { useRef } from "react";
function HomeTemplate({ cards, FAQQuestions }) {
  // const constraintsRef = useRef(null);

  return (
    <>
      <Header
        title="Welcome to"
        gradientTitle="RESALA"
        subTitle="Seamlessly Connecting your Business to Every Mobile Phone Worldwide"
        body="Seamlessly Connecting your Business to Every Mobile Phone Worldwide"
        isActions
        image={<EarthModel delay={0} duration={0.5} />}
        titleStyle={{ fontWeight: 700,fontSize: { xs: "50px", sm: "80px" } }}
        containerStyle={{fontSize: { xs: "50px", sm: "80px" },mb:"32px",fontWeight: 700
        }}
      />
      <Slider cards={cards} slidesToShow={{ sm: 1, md: 1, lg: 1, xl: 3 }} />
      <SendSMS
        title="Begin sending SMS and making voice calls in no time"
        body="Enhance your business communication through Resala. Keep your customers engaged and
          well-informed using the power of our SMS API, allowing you to reach your target audience
          globally and drive higher conversion rates and improved service."
        btnTitle="Join Now"
        handleBtnClick={(_) => window.open("https://retail.resala.io/")}
      />
      <OurPartners title="OUR PARTNERS" body={"We are privileged to be partnered with the leading names in our industry."}/>
      <FAQ title="Frequently Asked Questions" FAQQuestions={FAQQuestions} />
      <NewsLetter />      

    </>
  );
}

export default HomeTemplate;
