import CareersForm from "components/Organisms/careers/CareersForm";
import Header from "components/Organisms/page-header";
import React from "react";
import NewsLetter from "components/Molecules/Footer/NewsLetter";
export default function CareersTemplate({ formik, formFields }) {
  return (
    <>
      <Header
        title="Careers"
        body={
          <>
            We are a team of innovators who value skill, imagination and elegant solutions.<br />
            Join us now ! 
          </>
        }
      />
      <CareersForm
        title="Send us your CV"
        body="Please fill out the form for your job application"
        formFields={formFields}
        formik={formik}
      />
      <NewsLetter/>
    </>
  );
}
