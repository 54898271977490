import { List, ListItem, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useThemePalette } from "common/hooks/theme_palette";
import AppContainer from "components/Atoms/Container/AppContainer";
import CustomSlider from "components/Molecules/Custom-slider";

export default function Slider({ header, sx, data, isDotsLeft, listStyle }) {
  const { primary, darkGray } = useThemePalette();
  return (
    <Stack sx={{ pb: 5, pt: { xs: 1, md: 0 }, bgcolor: primary, ...sx }}>
      {/* <AppContainer sx={{ width: { xs: "100%", md: "85%" } }}> */}
        <CustomSlider
          header={header}
          centerMode={false}
          slidesToShow={1}
          isArrow={false}
          dots
          isDotsLeft={isDotsLeft}
        >
          {data?.map((item, i) => (
            <Stack key={i} sx={{ height: "100%", pb: { xs: 2, sm: 0 }, mb: 3, mt: 2 }}>
              <Stack
                direction={{ xs: "column", lg: "row" }}
                justifyContent="space-between"
                alignItems="center"
                sx={{ gap: { xs: 10, md: 5 } }}
              >
                {/* ------------ CONTENT ------------ */}
                <Stack sx={{ width: { xs: "100%", md: "60%" }, }}>
                  <Typography
                    sx={{
                      mb:"16px" ,
                      fontFamily: "Alef",
                      fontWeight: 700,
                      fontSize: { xs: "14px", md: "16px" },
                    }}
                  >
                    {item.title}
                  </Typography>
                  <List
                    sx={{
                      ...listStyle,
                      // height: listStyle?.height && i == 2 ? "140px" : listStyle?.height,
                    }}
                  >
                    {item.body.map((info, i) => (
                      <Stack key={i} direction="row" gap={1} alignItems="start">
                        <Typography
                          sx={{
                            fontSize: "15px",
                            color: darkGray,
                            fontFamily: "Alef",
                            lineHeight: { xs: "37px", md: "30px" },
                          }}
                        >
                          &#8226;
                        </Typography>
                        <Typography
                          sx={{
                            color: darkGray,
                            fontFamily: "Alef",
                            fontSize: "16px",
                            lineHeight: { xs: "37px", md: "32px" },
                          }}
                        >
                          {info}
                        </Typography>
                      </Stack>
                    ))}
                  </List>
                </Stack>
                <Stack width={item.width ? item.width : { xs: "100%", md: "50%" }} sx={{ mr: 0.5 }}>
                  <Box component="img" src={item.image} width="100%" />
                </Stack>
              </Stack>
            </Stack>
          ))}
        </CustomSlider>
      {/* </AppContainer> */}
    </Stack>
  );
}
