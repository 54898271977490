import { images } from "assets/AssetHelper";
import { useEffect } from "react";
import AboutUsTemplate from "templates/about-us/AboutUs.Template";

export default function AboutUsPage({size}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const data = [
    {
      info: "Our extensive array of communication products and tools enables businesses to connect with their customers through their preferred channel. With Resala, there's no more waiting on hold or navigating complex phone menus. Customers can swiftly and securely access the information they need, saving them time and frustration.",
      image: images.About1,
    },
    {
      info: "At Resala, we are devoted to delivering exceptional communication experiences. Our commitment to excellence knows no bounds, assisting businesses worldwide in building strong connections with their customers through the power of effective communication.",
      image: images.About2,
    },
  ];
  return <AboutUsTemplate data={data} size={size}/>;
}
